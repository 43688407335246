<svelte:head>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <meta name="description" content="Don't fret! We will return soon!" />
  <meta name="author" content="Snopes.com" />


  <!-- Bootstrap core CSS -->
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
    integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
    crossorigin="anonymous"
  />
  <link
    rel="stylesheet"
    href="https://pro.fontawesome.com/releases/v5.4.2/css/all.css"
    integrity="sha384-zhaLg9HKxTxDljOPXpWHGn91XMDH+sYAWRSgvzHes290/ISyrNicGrd6BInTnx3L"
    crossorigin="anonymous"
  />
  <link
    href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500"
    rel="stylesheet"
  />
</svelte:head>

<!-- <header>
  <nav class="navbar navbar-inverse p-0">
    <div class="container position-relative">
      <a class="navbar-brand p-0">
        <img
          src="https://www.snopes.com/uploads/2018/10/snopes-logo-S-crop-off-small.png"
          width="50"
          alt="Snopes Logo"
          title="Snopes.com"
        />
      </a>
    </div>
  </nav>
</header> -->
<div class="container">
  <div class="row px-2">
    <div class="col-lg-11 px-lg-5">
      <div class="card my-4 py-4 my-lg-5 py-lg-5 bg-transparent border-0">
        <div
          class="card-header border-top-0 border-left-0 border-right-0 p-0 pb-3 mb-5"
        >
          <!-- <h1 class="text-white small">Snopes is Offline</h1> -->
        </div>
        <div class="card-body p-0">
          <h3 class="h1 text-white">
            We'll be back after 10th June 2022
          </h3>
          <!-- <p class="lead text-white">
            Making a contribution won't speed things up, but it couldn't hurt.
          </p> -->
          <a
            class="btn btn-outline-warning"
            data-toggle="collapse"
            href="http://onelink.to/43fcmp"
            target="_blank"
            role="button"
            aria-expanded="false"
            aria-controls="details">Download MemeChat 👌</a>
          >
          <!-- <div class="collapse" id="details">
            <div class="panel card card-body text-white mb-0 mt-3 p-0">
              <p>
                Before you consider contributing, please note the following:
              </p>
              <ul>
                <li>
                  Any amounts sent to Snopes.com are purely donations and are
                  not a part of any offer to purchase securities or any other
                  interest in Snopes.com (or its parent company).
                </li>

                <li>
                  All donations are non-refundable, and you will own no equity,
                  debt, or other interest in Snopes.com (or its parent company)
                  as a result of your donation.
                </li>

                <li>
                  Since Snopes.com (and its parent company) is not a non-profit
                  501(c)(3) organization, donations are not tax-deductible as
                  charitable contributions, but all funds received will be
                  allocated in their entirety to our operating expenses, legal
                  fees, and the continuation of our overall mission to fight
                  misinformation.
                </li>
              </ul>
              <p>We appreciate your support!</p>
              <a
                class="btn btn-outline-warning"
                href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=BPS6PDAXAHRQJ"
                >Donate with PayPal</a
              >
            </div>
          </div> -->
          <hr class="my-5" />
          <p class="text-white small">Check for Updates</p>
          <a
            href="https://www.instagram.com/memechatapp/"
            class="btn-social btn btn-secondary mr-2"
            role="button"
            target="_blank"
            aria-pressed="true"><i class="fab fa-instagram" /></a
          >
          <a
            href="https://twitter.com/memechat_app"
            target="_blank"
            class="btn-social btn btn-secondary"
            role="button"
            aria-pressed="true"><i class="fab fa-twitter" /></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Bootstrap core JavaScript
		================================================== -->
<!-- Placed at the end of the document so the pages load faster -->

<style>
  /* body {
    background-color: #000000;
    font-family: "IBM Plex Sans", sans-serif;
  } */

  .navbar .container {
    height: 65px;
  }

  .navbar {
    background-color: #363645;
  }

  .navbar-brand {
    position: absolute;
    bottom: 0;
    left: 10px;
  }

  .btn-social {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 100%;
    background: transparent;
    border-color: #fbd43f;
    color: white;
    position: relative;
    border-width: 1px;
  }

  .btn-social:hover {
    background-color: #fbd43f;
    color: #000;
  }

  h2 {
    font-size: 2.3rem;
  }

  hr {
    border-bottom-color: rgba(255, 255, 255, 0.25);
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .text-yellow {
    color: #fbd43f;
  }

  .btn-outline-warning {
    color: white;
    border-color: var(--yellow);
    border-width: 2px;
    border-radius: 100px;
    padding: 0.65rem 1.5rem;
  }

  .btn-outline-warning:hover {
    color: #000;
    border-color: var(--yellow);
  }

  .card-header {
    border-bottom-color: rgba(255, 255, 255, 0.25);
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .btn-social {
    border-width: 2px;
    border-color: var(--yellow);
  }
  .btn-social:hover {
    border-color: var(--yellow);
  }

  .btn-social i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }

  .panel {
    background: #000;
    border-width: 0;
  }
</style>
